import styles from "./index.module.css";

const CardBanners = ({ setPage, setSport, isDarkTheme, hasCasino, hasLive, settings }) => {
  const handleClick = () => {
    setPage("sports");
    setSport("live");
  };

  const handleCasinoClick = () => {
    setPage("sports");
    setSport("casino");
  };

  const imageTheme = isDarkTheme ? "" : "";

  const renderLiveBanner = () => {
    if (!hasLive) {
      return null;
    }

    if (settings.destaque_ao_vivo) {
      return (
        <div className={styles.CardWrapper} onClick={() => handleClick()}>
          <picture>
            <source
              srcSet={settings.destaque_ao_vivo}
              media="(min-width: 600px)"
            />
            <img src={settings.destaque_ao_vivo_mobile} />
          </picture>
          {settings.destaque_ao_vivo_texto &&
            <div className={styles.InfoWrapper}>
              <h3>Apostas ao vivo</h3>
              <p>
                Mais de 90.000+ jogos ao vivo por mês e milhares de opções para
                apostas!
              </p>
              <button
                className={`${styles.Button} btn-primary`}
                type="button"
                onClick={() => handleClick()}
              >
                Apostar
              </button>
            </div>
          }
        </div>
      );
    }

    return (
      <div className={styles.CardWrapper} onClick={() => handleClick()}>
        <picture>
          <source
            srcSet={`./images/landingPage/bannerLive${imageTheme}.png`}
            media="(min-width: 600px)"
          />
          <img src={`./images/landingPage/bannerLiveMobile${imageTheme}.png`} />
        </picture>
        <div className={styles.InfoWrapper}>
          <h3>Apostas ao vivo</h3>
          <p>
            Mais de 90.000+ jogos ao vivo por mês e milhares de opções para
            apostas!
          </p>
          <button
            className={`${styles.Button} btn-primary`}
            type="button"
            onClick={() => handleClick()}
          >
            Apostar
          </button>
        </div>
      </div>
    );
  };

  const renderSportsBanner = () => {
    if (settings.destaque_esportes) {
      return (
        <div className={styles.CardWrapper} onClick={() => setPage("sports")}>
          <picture>
            <source
              srcSet={settings.destaque_esportes}
              media="(min-width: 600px)"
            />
            <img src={settings.destaque_esportes_mobile} />
          </picture>
          {settings.destaque_esportes_texto &&
            <div className={`${styles.InfoWrapper} ${styles.AlignRight}`}>
              <button
                className={`${styles.Button} btn-primary`}
                type="button"
                onClick={() => setPage("sports")}
              >
                Apostar
              </button>
              <p>Aposte nos principais eventos da semana!</p>
              <h3>Apostas Esportivas</h3>
            </div>
          }
        </div>
      );
    }

    return (
      <div className={styles.CardWrapper} onClick={() => setPage("sports")}>
        <picture>
          <source
            srcSet={`./images/landingPage/bannerSports${imageTheme}.png`}
            media="(min-width: 600px)"
          />
          <img
            src={`./images/landingPage/bannerSportsMobile${imageTheme}.png`}
          />
        </picture>
        <div className={`${styles.InfoWrapper} ${styles.AlignRight}`}>
          <button
            className={`${styles.Button} btn-primary`}
            type="button"
            onClick={() => setPage("sports")}
          >
            Apostar
          </button>
          <p>Aposte nos principais eventos da semana!</p>
          <h3>Apostas Esportivas</h3>
        </div>
      </div>
    );
  };

  const renderCasinoBanner = () => {
    if (!hasCasino) {
      return null;
    }

    if (settings.destaque_cassino) {
      return (
        <div className={styles.CardWrapper} onClick={() => handleCasinoClick()}>
          <picture>
            <source
              srcSet={settings.destaque_cassino}
              media="(min-width: 600px)"
            />
            <img src={settings.destaque_cassino_mobile} />
          </picture>
          {settings.destaque_cassino_texto &&
            <div className={styles.InfoWrapper}>
              <h3>Cassino</h3>
              <p>Os melhores provedores de cassino do mundo.</p>
              <button
                className={`${styles.Button} btn-primary`}
                type="button"
                onClick={() => handleCasinoClick()}
              >
                Jogar
              </button>
            </div>
          }
        </div>
      );
    }

    return (
      <div className={styles.CardWrapper} onClick={() => handleCasinoClick()}>
        <picture>
          <source
            srcSet={`./images/landingPage/bannerCasino${imageTheme}.png`}
            media="(min-width: 600px)"
          />
          <img src={`./images/landingPage/bannerCasinoMobile${imageTheme}.png`} />
        </picture>
        <div className={styles.InfoWrapper}>
          <h3>Cassino</h3>
          <p>Os melhores provedores de cassino do mundo.</p>
          <button
            className={`${styles.Button} btn-primary`}
            type="button"
            onClick={() => handleCasinoClick()}
          >
            Jogar
          </button>
        </div>
      </div>
    );
  };

  return (
    <section className={styles.Wrapper}>
      {renderLiveBanner()}
      {renderSportsBanner()}
      {renderCasinoBanner()}
    </section>
  );
};

export default CardBanners;
